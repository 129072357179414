import * as Yup from "yup";
const libraryInitialValues = {
  skillId: "",
  title: "",
  type: "",
  time: "",
  cost: "",
  author: "",
  heading: "",
  description: "",
  url: "",
  backgroundImg: "",
  resource_id: "",
};
const libraryValidationSchema = Yup.object().shape({
  skillId: Yup.string().trim().required("Skill is required*"),
  title: Yup.string().trim().required("Title is required*"),
  type: Yup.string().trim().required("Type is required*"),
  time: Yup.string().trim().required("Time is required*"),
  cost: Yup.string().trim().required("Cost is required*"),
  author: Yup.string().trim().required("Author is required*"),
  heading: Yup.string().trim().required("Heading is required*"),
  description: Yup.string().trim().required("Description is required*"),
  url: Yup.string()
    .trim()
    .url("Please enter a valid URL")
    .required("URL is required*"),
  backgroundImg: Yup.string().trim().required("Image is required*"),

  imgId: Yup.string().trim(),
});

export { libraryInitialValues, libraryValidationSchema };
