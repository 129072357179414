import * as Yup from "yup";

const sendNotificationInitialValues = {
  title: "",
  message: "",
};
const sendNotificationValidationSchema = Yup.object().shape({
  title: Yup.string().trim().required(" Title is required*"),
  message: Yup.string().trim().required(" Message is required*"),
});
export { sendNotificationInitialValues, sendNotificationValidationSchema };
