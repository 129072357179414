/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import ScreenLayout from "../../components/UI/screenLayout";
import TempLayout from "../../components/UI/tempLayout";
import usePost from "../../hooks/usePost";
import ENDPOINTS from "../../services/endPoints";
import QUERY_KEYS from "../../services/queryKeys";
import { toast } from "react-toastify";
import { uniqueIdGenerator } from "../../utils/helpers";
import { RoutePath } from "../../routes/routePath";
import { useNavigate } from "react-router-dom";

const Intro = ({ props }) => {
  const {
    data,
    templateId,
    handleEditForm,
    editTemplate,
    refetchStepDetail,
    dataLoading,
    stepId,
  } = props;

  const [subForm, setSubForm] = useState([
    {
      formId: uniqueIdGenerator(),
      formType: "scroll",
      description:
        "Challenge your perceptions, explore gendered norms, and maybe even discover something new.",
    },
    {
      formId: uniqueIdGenerator(),
      formType: "scroll",
      description:
        "Challenge your perceptions, explore gendered norms, and maybe even discover something new.",
    },
    {
      formId: uniqueIdGenerator(),
      formType: "scroll",
      description:
        "Challenge your perceptions, explore gendered norms, and maybe even discover something new.",
    },
  ]);

  const [finalForm, setFinalForm] = useState({
    formId: uniqueIdGenerator(),
    formType: "primary",
    description:
      "Read each statement and decide whether you agree or disagree with it.",
    buttonText: "Let's go",
  });

  const [introData, setIntroData] = useState([
    {
      name: data?.name ?? "Intro",
      form_json: [
        {
          subForms: subForm,
        },
        finalForm,
      ],
    },
  ]);
  const nav = useNavigate();
  const {
    mutateAsync: addTemplateMutateAsync,
    isLoading: isLoadingAddTemplate,
  } = usePost(
    ENDPOINTS.TEMPLATE_DATA_ADD,
    QUERY_KEYS.TEMPLATE_DATA_ADD_QUERY_KEY
  );

  const uploadFormJson = async (formData) => {
    const params = {
      step_id: stepId,
      sub_step_id: data?._id,
      template_id: templateId,
      template_json: formData,
    };

    try {
      const response = await addTemplateMutateAsync(params);

      if (response.statusCode === 200) {
        refetchStepDetail();
        toast.success(response?.message);
        handleEditForm();
        nav(RoutePath.SKILL_DETAIL.path);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
    //add upload form api
  };

  const handelApply = async () => {
    const formData = [
      {
        name: data?.name ?? "Intro",
        form_json: [
          {
            subForms: subForm,
          },
          finalForm,
        ],
      },
    ];
    setIntroData(formData);

    uploadFormJson(formData);
  };

  const handleSubForm = (event, index, key) => {
    setSubForm((prevSubForm) => {
      prevSubForm[index][key] = event.target.innerText;
      return [...prevSubForm];
    });
  };

  const handleFinalForm = (event, key) => {
    setFinalForm({ ...finalForm, [key]: event.target.innerText });
  };
  useEffect(() => {
    if (data?.template_id == 0) {
      const forms = data?.form_json;
      if (forms && forms?.length !== 0) {
        setIntroData(forms[0]?.form_json);
        setFinalForm(forms[0]?.form_json[1]);
        setSubForm(forms[0]?.form_json[0]?.subForms);
      } else {
        setIntroData(introData);
        handleEditForm(true);
      }
    }
  }, [, data?.form_json, refetchStepDetail]);

  return (
    <TempLayout
      handleEditForm={handleEditForm}
      editTemplate={editTemplate}
      handleApply={handelApply}
      loading={isLoadingAddTemplate}
      dataLoading={dataLoading}
    >
      <section className="screen-section">
        <div className="container-fluid gx-0">
          <div className="row gap-3">
            <div className="col-12">
              <div className="screen-wrapper">
                {subForm?.map((item, index) => {
                  return (
                    <ScreenLayout notHeaderRequire={true}>
                      <div className="screen-body">
                        <div className="screen-content">
                          <div
                            className="checkbox-wrapper"
                            style={{ maxHeight: "461px", marginTop: "0px" }}
                          >
                            <div className="checkbox-list">
                              <p
                                contentEditable={editTemplate}
                                onBlur={(e) =>
                                  handleSubForm(e, index, "description")
                                }
                              >
                                {item?.description}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="screen-footer">
                        <div className="footer-nav-indicator">
                          <span className="dots active" />
                          <span className="dots" />
                          <span className="dots" />
                        </div>
                      </div>
                    </ScreenLayout>
                  );
                })}
                <ScreenLayout notHeaderRequire={true}>
                  <div className="screen-body">
                    <div className="screen-content">
                      <div
                        className="checkbox-wrapper"
                        style={{ maxHeight: "427px", marginTop: "0px" }}
                      >
                        <div className="checkbox-list">
                          <p
                            contentEditable={editTemplate}
                            onBlur={(e) => handleFinalForm(e, "description")}
                          >
                            {finalForm?.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="screen-footer">
                    <div className="footer-nav-btn">
                      <div
                        className="btn-style"
                        contentEditable={editTemplate}
                        onBlur={(e) => handleFinalForm(e, "buttonText")}
                      >
                        {finalForm?.buttonText}
                      </div>
                    </div>
                  </div>
                </ScreenLayout>
              </div>
            </div>
          </div>
        </div>
      </section>
    </TempLayout>
  );
};

export default Intro;
