import React from "react";
import ImagesUrl from "../../../utils/constants/images";

const LeaderboardListRow = ({ data, pageNumber, countData }) => {
  const images = [ImagesUrl.rank_1, ImagesUrl.rank_2, ImagesUrl.rank_3];
  return (
    <>
      {data?.map((item, index) => {
        return (
          <tr key={index}>
            <td>
              <div className="d-flex mx-3">
                {pageNumber === 1 ? (
                  index < 3 ? (
                    <img src={images[index]} alt={index} />
                  ) : (
                    index + 1
                  )
                ) : (
                  (pageNumber - 1) * countData + index + 1
                )}
              </div>
            </td>

            <td>
              <div className="d-flex mx-3 ">
                <img
                  src={ImagesUrl.avatar_icon}
                  className="avatar avatar-md rounded-lg"
                  alt="spotify"
                />
              </div>
            </td>
            <td className="align-middle px-4">
              {item?.first_name && item?.last_name ? (
                <span className="text-secondary text-sm font-weight-bold text-center">
                  {`${item?.first_name} ${item?.last_name}`}
                </span>
              ) : (
                <span className="text-secondary text-sm font-weight-bold text-center">
                  N/A
                </span>
              )}
            </td>
            <td className="align-middle px-4">
              <span className="text-secondary text-sm font-weight-bold text-center">
                {item.email || "N/A"}
              </span>
            </td>
            {/* <td className="align-middle px-4 ">{item.phone_number || "N/A"}</td> */}
            <td className="align-middle px-4 ">
              <span className="text-secondary text-sm font-weight-bold text-center">
                {item.earned_points}
              </span>
            </td>
            {/* <td className="align-middle px-4">
              <span
                style={{ width: "100px" }}
                class={
                  item?.status === 1
                    ? "badge badge-sm bg-gradient-success"
                    : "bg-gradient-secondary badge badge-sm"
                }
              >
                {item?.status === 1 ? "Active" : "Suspended"}
              </span>
            </td> */}
          </tr>
        );
      })}
    </>
  );
};

export default LeaderboardListRow;
