import React, { useState } from "react";

import NotificationArray from "../../utils/seeds/notificationArray";
import Notification from "./notification";
import ScheduleNotification from "./scheduleNotification";

const NotificationManagement = () => {
  const [templateActive, setTemplateActive] = useState(0);
  const handelActiveTemplate = (index) => {
    setTemplateActive(index);
    window.scrollTo(0, 300);
  };

  const ContentType = () => {
    if (templateActive === 0) {
      return <Notification />;
    } else if (templateActive === 1) {
      return <ScheduleNotification />;
    }
  };
  return (
    <div>
      <div className="content-card-wrapper">
        <div className="row row-cols-2 row-cols-sm-2 row-cols-lg-5 row-cols-xl-4 gy-4">
          {NotificationArray?.map((item, index) => {
            return (
              <div
                className="col cursor-pointer"
                key={index}
                onClick={() => handelActiveTemplate(index)}
              >
                <div
                  className={templateActive == index ? "card active" : "card"}
                  style={{ height: "100%" }}
                >
                  <div className="card-header  text-center">
                    <div className="icon icon-shape icon-sm bg-gradient-primary shadow text-center border-radius-sm">
                      {item?.icon}
                    </div>
                  </div>
                  <div className="card-body p-2 m-0 text-center">
                    <h6 className="text-center ">{item?.name}</h6>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <ContentType />
    </div>
  );
};

export default NotificationManagement;
