const NotificationArray = [
  {
    id: "1",
    icon: <i className="fa-regular fa-address-card" aria-hidden="true" />,
    name: "Notification",
  },
  {
    id: "2",
    icon: <i className="fa-solid fa-circle-info" aria-hidden="true" />,
    name: "Scheduled Notification",
  },
];
export default NotificationArray;
