/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import {
  addJourneyInitialValues,
  addJourneyValidationSchema,
} from "../../utils/validations/joureny";
import SkillsList from "../skillManagement/skillsList";
import CustomModal from "../../components/customModal/customModal";
import useGet from "../../hooks/useGet";
import ENDPOINTS from "../../services/endPoints";
import QUERY_KEYS from "../../services/queryKeys";
import userGlobalConfig from "../../utils/constants/globalConfig";
import useDelete from "../../hooks/useDelete";
import { toast } from "react-toastify";
import { RoutePath } from "../../routes/routePath";
import { useNavigate } from "react-router-dom";
import usePost from "../../hooks/usePost";
import usePut from "../../hooks/usePut";
import DetailSkeleton from "../../utils/skeleton/detailsSkeleton";
import ImagesUrl from "../../utils/constants/images";

const JourneyDetail = () => {
  const [deleteJourneyModal, setDeleteJourneyModal] = useState(false);
  const [deleteJourneyId, setDeleteJourneyId] = useState(null);
  const [EditJourneyModal, setEditJourneyModal] = useState(false);
  const [uploadImg, setUploadImg] = useState(null);
  const [uploadBackgroundImg, setUploadBackgroundImg] = useState(null);
  const [uploadQuestionImg, setUploadQuestionImg] = useState(null);
  const uploadImageRef = useRef(null);
  const uploadBackgroundImageRef = useRef(null);
  const uploadQuestionImageRef = useRef(null);

  const nav = useNavigate();
  const {
    data: journeyDetailData,
    isLoading: isLoadingJourneyDetail,
    refetch: refetchJourneyDetail,
  } = useGet(ENDPOINTS.DETAIL_JOURNEY, QUERY_KEYS.DETAIL_JOURNEY_QUERY_KEY, {
    _id: JSON.parse(localStorage.getItem(userGlobalConfig?.JOURNEY_ID)),
    page: 1,
    limit: 10,
  });
  const {
    isLoading: isLoadingJourneyDelete,
    mutateAsync: JourneyDeleteMutateAsync,
  } = useDelete(ENDPOINTS.DELETE_JOURNEY, QUERY_KEYS.DELETE_JOURNEY_QUERY_KEY);
  const {
    isLoading: isLoadingJourneyStatus,
    mutateAsync: journeyStatusMutateAsync,
  } = usePost(ENDPOINTS.STATUS_JOURNEY, QUERY_KEYS.STATUS_JOURNEY_QUERY_KEY);
  const {
    mutateAsync: editJourneyMutateAsync,
    isLoading: isLoadingEditJourney,
  } = usePut(ENDPOINTS.EDIT_JOURNEY, QUERY_KEYS.EDIT_JOURNEY_QUERY_KEY);
  const journeyData = journeyDetailData?.data;
  const handleStatusChange = async (item) => {
    try {
      let statusCode = item.status === 1 ? "2" : "1";
      const response = await journeyStatusMutateAsync({
        _id: item?._id,
        status: statusCode,
      });
      if (response.status === true) {
        toast.success(response?.message);
        refetchJourneyDetail();
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };
  const onChangeFile = (target) => {
    const selectedFile = target.files[0];
    setUploadImg(URL.createObjectURL(selectedFile));
    setFieldValue("journeyImage", selectedFile);
    target.value = "";
  };
  const onChangeBackgroundFile = (target) => {
    const selectedFile = target.files[0];
    setUploadBackgroundImg(URL.createObjectURL(selectedFile));
    setFieldValue("journeyBackgroundImg", selectedFile);
    target.value = "";
  };
  const onChangeQuestionFile = (target) => {
    const selectedFile = target.files[0];
    setUploadQuestionImg(URL.createObjectURL(selectedFile));
    setFieldValue("journeyQuestionImage", selectedFile);
    target.value = "";
  };
  const handleSelectUploadImage = () => {
    if (uploadImageRef.current) {
      uploadImageRef.current.click();
    }
  };
  const handleSelectBackgroundUploadImage = () => {
    if (uploadBackgroundImageRef.current) {
      uploadBackgroundImageRef.current.click();
    }
  };
  const handleSelectUploadQuestionImage = () => {
    if (uploadQuestionImageRef.current) {
      uploadQuestionImageRef.current.click();
    }
  };
  const handelRemoveUploadImg = () => {
    setUploadImg(null);
    setFieldValue("journeyImage", "");
  };
  const handelRemoveBackgroundUploadImg = () => {
    setUploadBackgroundImg(null);
    setFieldValue("journeyBackgroundImg", "");
  };
  const handelRemoveQuestionImg = () => {
    setUploadQuestionImg(null);
    setFieldValue("journeyQuestionImage", "");
  };
  const handelDeleteJourneyModal = (id) => {
    setDeleteJourneyId(id);
    setDeleteJourneyModal(true);
  };
  const onDeleteConfirm = async () => {
    try {
      const response = await JourneyDeleteMutateAsync({
        _id: deleteJourneyId,
      });
      if (response.status === true) {
        toast.success(response?.message);
        setDeleteJourneyModal(false);
        nav(RoutePath.JOURNEY_MANAGEMENT.path);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  const {
    errors,
    handleBlur,
    handleChange,
    touched,
    resetForm,
    handleSubmit,
    setValues,
    values,
    setFieldValue,
  } = useFormik({
    initialValues: addJourneyInitialValues,
    validationSchema: addJourneyValidationSchema,
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("_id", journeyData?._id);
      formData.append("name", values?.journeyName);
      formData.append("description", values?.journeyDescription);
      formData.append("image", values?.journeyImage);
      formData.append("background_image", values?.journeyBackgroundImg);
      formData.append("assessment_question", values?.journeyQuestion);
      formData.append("assessment_image", values?.journeyQuestionImage);
      try {
        const response = await editJourneyMutateAsync(formData);
        if (response.status === true) {
          toast.success(response?.message);
          resetForm();
          setEditJourneyModal(false);
          refetchJourneyDetail();
        } else {
          toast.error(response?.message);
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    },
  });
  useEffect(() => {
    setValues({
      journeyName: journeyData?.name,
      journeyDescription: journeyData?.description,
      journeyImage: journeyData?.image,
      journeyBackgroundImg: journeyData?.background_image,
    });
  }, [journeyData, EditJourneyModal]);
  return (
    <div>
      <div className="container-fluid py-4">
        <div className="row">
          {isLoadingJourneyDetail ? (
            <DetailSkeleton />
          ) : (
            <div className="card position-relative">
              <img
                className="back_btn"
                src={ImagesUrl.back_btn}
                alt=".."
                onClick={() =>
                  nav(RoutePath.JOURNEY_MANAGEMENT.path, {
                    state: { headerName: RoutePath.JOURNEY_MANAGEMENT.title },
                  })
                }
              />
              <div className="card-body">
                <div className="row gy-3">
                  <div className="col-12">
                    <div className="d-flex justify-content-end align-items-center gap-1">
                      <i
                        className="fas fa-pencil-alt text-dark px-1 cursor-pointer"
                        onClick={() => setEditJourneyModal(true)}
                      ></i>

                      <i
                        className="far fa-trash-alt text-danger px-1 cursor-pointer "
                        onClick={() =>
                          handelDeleteJourneyModal(journeyData?._id)
                        }
                      ></i>
                      <span className="toggle-box px-1">
                        <input
                          onClick={() => handleStatusChange(journeyData)}
                          type="checkbox"
                          checked={journeyData?.status === 1 ? true : false}
                          id={1}
                          disabled={isLoadingJourneyStatus}
                        />
                        <label className="m-0" htmlFor={1} />
                      </span>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="row">
                      <div className="col-sm-4 col-lg-3">
                        <div className="full-image-blk">
                          <img src={journeyData?.image} alt=".." />
                        </div>
                      </div>
                      <div className="col-sm-8 col-lg-9">
                        <div className="content-blk">
                          <h1 className="h2">{journeyData?.name}</h1>
                          <p>{journeyData?.description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <SkillsList skillsData={journeyData} />
      </div>
      <CustomModal
        open={EditJourneyModal}
        title={"Edit Journey"}
        actionTitle={"Save"}
        cancelTitle={"Cancel"}
        actionButtonClass={"bg-danger"}
        onConfirm={handleSubmit}
        isLoading={isLoadingEditJourney}
        onClose={() => {
          setEditJourneyModal(false);
          setUploadImg(null);
        }}
      >
        <div>
          <form>
            <div className="upload_container">
              <div>
                <div className="drag-input-field">
                  <label>Upload Image </label>
                  <input
                    type="file"
                    accept="image/*"
                    name="journeyImage"
                    onBlur={handleBlur}
                    onChange={({ target }) => {
                      onChangeFile(target);
                    }}
                    ref={uploadImageRef}
                  />
                  {values?.journeyImage?.length === 0 ? (
                    <label className="m-0" onClick={handleSelectUploadImage}>
                      <span className="upload-text">
                        <i className="fa-solid fa-cloud-arrow-up" />
                        Upload
                      </span>
                    </label>
                  ) : (
                    <div className="position-relative ">
                      <img
                        src={
                          uploadImg == null ? values.journeyImage : uploadImg
                        }
                        style={{
                          height: "200px",
                          width: "100%",
                          objectFit: "cover",
                          borderRadius: "5px",
                        }}
                        alt="..."
                      />
                      <button
                        className="removeimg"
                        type="button"
                        onClick={() => handelRemoveUploadImg()}
                      >
                        X
                      </button>
                    </div>
                  )}
                </div>
                {touched.journeyImage && errors.journeyImage && (
                  <p className="error" style={{ marginTop: "4px" }}>
                    {errors.journeyImage}
                  </p>
                )}
              </div>
              <div>
                <div className="mb-3">
                  <label> Name</label>
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    placeholder="Enter Journey Name"
                    name="journeyName"
                    value={values.journeyName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                {touched.journeyName && errors.journeyName && (
                  <p className="error">{errors.journeyName}</p>
                )}
                <div className="mb-3">
                  <label> Description </label>
                  <textarea
                    className="form-control form-control-lg"
                    placeholder="Enter Journey Description"
                    rows="3"
                    cols="50"
                    name="journeyDescription"
                    value={values.journeyDescription}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  ></textarea>
                </div>
                {touched.journeyDescription && errors.journeyDescription && (
                  <p className="error">{errors.journeyDescription}</p>
                )}
              </div>
            </div>

            <div className="drag-input-field mt-3">
              <label>Upload Background Image </label>
              <input
                type="file"
                accept="image/*"
                name="journeyBackgroundImg"
                onBlur={handleBlur}
                onChange={({ target }) => {
                  onChangeBackgroundFile(target);
                }}
                ref={uploadBackgroundImageRef}
              />
              {values?.journeyBackgroundImg?.length === 0 ? (
                <label
                  className="m-0"
                  onClick={handleSelectBackgroundUploadImage}
                >
                  <span className="upload-text">
                    <i className="fa-solid fa-cloud-arrow-up" />
                    Upload image
                  </span>
                </label>
              ) : (
                <div className="position-relative border-1 border border-gray rounded-2 d-flex justify-content-center p-2">
                  <img
                    src={
                      uploadBackgroundImg == null
                        ? values.journeyBackgroundImg
                        : uploadBackgroundImg
                    }
                    style={{
                      height: "200px",
                      width: "200px",

                      objectFit: "contain",
                      borderRadius: "5px",
                      border: "solid 1px #8080808a",
                    }}
                    alt="..."
                  />
                  <button
                    className="removeimg"
                    type="button"
                    onClick={() => handelRemoveBackgroundUploadImg()}
                  >
                    X
                  </button>
                </div>
              )}
            </div>
            {touched.journeyBackgroundImg && errors.journeyBackgroundImg && (
              <p className="error" style={{ marginTop: "4px" }}>
                {errors.journeyBackgroundImg}
              </p>
            )}
            {/* <div className="upload_container mt-1">
              <div>
                <div className="drag-input-field">
                  <label>Upload Question Image </label>
                  <input
                    type="file"
                    accept="image/*"
                    name="journeyQuestionImage"
                    onBlur={handleBlur}
                    onChange={({ target }) => {
                      onChangeQuestionFile(target);
                    }}
                    ref={uploadQuestionImageRef}
                  />
                  {values?.journeyQuestionImage?.length === 0 ? (
                    <label
                      className="m-0"
                      onClick={handleSelectUploadQuestionImage}
                    >
                      <span className="upload-text">
                        <i className="fa-solid fa-cloud-arrow-up" />
                        Upload
                      </span>
                    </label>
                  ) : (
                    <div className="position-relative ">
                      <img
                        src={
                          uploadQuestionImg == null
                            ? values.journeyQuestionImage
                            : uploadQuestionImg
                        }
                        style={{
                          height: "200px",
                          width: "100%",
                          objectFit: "cover",
                          borderRadius: "5px",
                        }}
                        alt="..."
                      />
                      <button
                        className="removeimg"
                        type="button"
                        onClick={() => handelRemoveQuestionImg()}
                      >
                        X
                      </button>
                    </div>
                  )}
                </div>
                {touched.journeyQuestionImage &&
                  errors.journeyQuestionImage && (
                    <p className="error" style={{ marginTop: "4px" }}>
                      {errors.journeyQuestionImage}
                    </p>
                  )}
              </div>
              <div>
                <div className="mb-3 mt-2">
                  <label> Question </label>
                  <textarea
                    className="form-control form-control-lg"
                    placeholder="Enter Question"
                    rows="7"
                    cols="50"
                    name="journeyQuestion"
                    value={values.journeyQuestion}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  ></textarea>
                </div>
                {touched.journeyQuestion && errors.journeyQuestion && (
                  <p className="error">{errors.journeyQuestion}</p>
                )}
              </div>
            </div> */}
          </form>
        </div>
      </CustomModal>
      <CustomModal
        customClass="sm-modal"
        open={deleteJourneyModal}
        title={"Journey Delete"}
        actionTitle={"Delete"}
        cancelTitle={"Cancel"}
        actionButtonClass={"bg-danger"}
        onConfirm={onDeleteConfirm}
        isLoading={isLoadingJourneyDelete}
        onClose={() => setDeleteJourneyModal(false)}
      >
        <div>
          <span>Are you sure you want to delete journey?</span>
        </div>
      </CustomModal>
    </div>
  );
};

export default JourneyDetail;
