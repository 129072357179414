import React, { useEffect, useState } from "react";
import ImagesUrl from "../../utils/constants/images";
import TempLayout from "../../components/UI/tempLayout";
import { uniqueIdGenerator } from "../../utils/helpers";
import { toast } from "react-toastify";
import usePost from "../../hooks/usePost";
import ENDPOINTS from "../../services/endPoints";
import QUERY_KEYS from "../../services/queryKeys";
import ScreenLayout from "../../components/UI/screenLayout";
import { RoutePath } from "../../routes/routePath";
import { useNavigate } from "react-router-dom";
import QuestionOptions from "../../components/UI/questionOption";

const JourneyQuiz = ({ props }) => {
  const { data, templateId, handleEditForm, editTemplate, stepId } = props;

  const [form1, setForm1] = useState({
    formId: uniqueIdGenerator(),
    formType: "simple continue",
    description: "It’s time to put your knowledge to the test!",
    buttonText: "Tap To Continue",
  });
  const [form2, setForm2] = useState({
    formId: uniqueIdGenerator(),
    formType: "primary",
    description: "You can review what you’ve learned or jump straight in.",
    returnHomeButtonText: "Start the Quiz",
    buttonText: "Go through Recap",
  });
  const [insightSubForm, setInsightSubForm] = useState([
    {
      formId: uniqueIdGenerator(),
      formType: "part continue",
      subTitle: "Insight #1",
      description:
        "Adapting to the situation with both strength and warmth and taking on this mindset of balancing different leadership styles can help women succeed.",
      buttonText: "Tap to continue",
    },
  ]);
  const [middleForm, setMiddleForm] = useState({
    formId: uniqueIdGenerator(),
    formType: "primary",
    isMiddleForm: true,
    description:
      "Now that you've reviewed key insights for this skill, it's quiz time! you've got this. Ready",
    buttonText: "I'm ready",
  });
  const [questionSubform, setQuestionSubform] = useState([
    {
      formId: uniqueIdGenerator(),
      formType: "quiz",
      question: "Which of these statements sounds the least confident?",
      options: [
        "“Let’s go with the more innovative project to highlight our tech.”",
        "“I just want you to know I feel more comfortable with the first project.”",
        "“I could be wrong but that project might align better with our goals.”",
        "“I don’t have a preference; go with what you think is best.”",
      ],
      answer: 1,
      incorrectDescription:
        "Confidence can be portrayed in many different elements of the way you show up.",
      correctDescription:
        "When we try new things, we build confidence by showing ourselves what we’re capable of doing",
      buttonText: "Submit option",
    },
  ]);
  const nav = useNavigate();
  const {
    mutateAsync: addTemplateMutateAsync,
    isLoading: isLoadingAddTemplate,
  } = usePost(
    ENDPOINTS.TEMPLATE_DATA_ADD,
    QUERY_KEYS.TEMPLATE_DATA_ADD_QUERY_KEY
  );

  const addNewInsightForm = () => {
    let length = insightSubForm.length + 1;
    setInsightSubForm([
      ...insightSubForm,
      {
        formId: uniqueIdGenerator(),
        formType: "part continue",
        subTitle: `Insight #${length}`,
        description:
          "Despite being one of the top performers in sales consistently over the past years, you know that you are paid a bit less then most of your colleagues.",
        buttonText: "Tap to continue",
      },
    ]);
  };

  const addNewQuestionForm = () => {
    setQuestionSubform([
      ...questionSubform,

      {
        formId: uniqueIdGenerator(),
        formType: "quiz",
        question: "What would you do?",
        options: [
          "Wait a few more months then bring up the issue. They might be planning to give you a raise and you don't want to seem demanding. ",
          "Talk to a colleague who you trust and knows you well to see if they feel you are due a raise.",
          "Launch a more thorough investigation into the job market, seeking out any lower paying jobs similar to yours in case there is justification for your current level.",
          "Email your manager about meeting to discuss a raise, include a brief outline of your contributions, relevant market research for similar jobs, and a pay range you feel is suitable. ",
        ],
        answer: 1,
        incorrectDescription:
          "Confidence can be portrayed in many different elements of the way you show up.",
        correctDescription:
          "When we try new things, we build confidence by showing ourselves what we’re capable of doing",
        buttonText: "Submit option",
      },
    ]);
  };
  const handleInsightForm = (event, index, key) => {
    setInsightSubForm((prevSubForm) => {
      prevSubForm[index][key] = event.target.innerText;
      return [...prevSubForm];
    });
  };
  const updateQuestion = (event, index, key) => {
    setQuestionSubform((prevQuestionSubform) => {
      const newQuestionSubform = [...prevQuestionSubform];
      newQuestionSubform[index][key] = event.target.innerText;
      return newQuestionSubform;
    });
  };
  const removeInsightForms = (index) => {
    const newSubForm = [...insightSubForm];
    newSubForm.splice(index, 1);
    setInsightSubForm(newSubForm);
  };
  const removeQuestionForms = (index) => {
    const newSubForm = [...questionSubform];
    newSubForm.splice(index, 1);
    setQuestionSubform(newSubForm);
  };
  const handleQuestionForm = (event, index, key, subIndex) => {
    setQuestionSubform((prevSubForm) => {
      prevSubForm[index][key][subIndex] = event.target.innerText;
      return [...prevSubForm];
    });
  };
  const handleQuestionAns = (event, index, key) => {
    setQuestionSubform((prevSubForm) => {
      prevSubForm[index][key] = event.target.value;
      return [...prevSubForm];
    });
  };
  const handelApply = () => {
    const formData = [
      {
        name: data?.name ?? "Quiz",
        form_json: [
          form1,
          form2,
          {
            subForms: insightSubForm,
          },
          middleForm,
          {
            subForms: questionSubform,
          },
        ],
      },
    ];

    uploadFormJson(formData);
  };
  const uploadFormJson = async (formData) => {
    const params = {
      step_id: stepId,
      sub_step_id: data?._id,
      template_id: templateId,
      template_json: formData,
    };

    try {
      const response = await addTemplateMutateAsync(params);

      if (response.statusCode === 200) {
        toast.success(response?.message);
        nav(RoutePath.SKILL_DETAIL.path);
        handleEditForm();
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error(error?.response.data?.message);
    }
    //add upload form api
  };
  useEffect(() => {
    if (data?.template_id == 9) {
      const forms = data?.form_json;

      if (forms && forms?.length !== 0) {
        setForm1(forms[0]?.form_json[0]);
        setForm2(forms[0]?.form_json[1]);
        if (forms[0]?.form_json[3]?.isMiddleForm) {
          setMiddleForm(forms[0]?.form_json[3]);
          setQuestionSubform(forms[0]?.form_json[4]?.subForms);
        } else {
          setQuestionSubform(forms[0]?.form_json[3]?.subForms);
        }
      } else {
        handleEditForm(true);
      }
    }
  }, [data]);
  return (
    <TempLayout
      handleEditForm={handleEditForm}
      editTemplate={editTemplate}
      handleApply={() => handelApply()}
      loading={isLoadingAddTemplate}
    >
      <section className="screen-section">
        <div className="container-fluid gx-0">
          <div className="row gap-3">
            <div className="col-12">
              <div className="screen-wrapper">
                <ScreenLayout name={data.name}>
                  <div className="screen-body">
                    <div
                      className="checkbox-wrapper"
                      style={{ maxHeight: "395px", marginTop: "0px" }}
                    >
                      <div className="checkbox-list">
                        <div className="screen-content">
                          <p
                            contentEditable={editTemplate}
                            onBlur={(e) => {
                              setForm1({
                                ...form1,
                                description: e.target.innerText,
                              });
                            }}
                          >
                            {form1.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="screen-footer">
                    <div className="footer-nav-btn">
                      <div
                        className="btn-text-style"
                        contentEditable={editTemplate}
                        onBlur={(e) => {
                          setForm1({
                            ...form1,
                            buttonText: e.target.innerText,
                          });
                        }}
                      >
                        {form1.buttonText}
                      </div>
                    </div>
                  </div>
                </ScreenLayout>
                <ScreenLayout name={data.name}>
                  <div className="screen-body">
                    <div
                      className="checkbox-wrapper"
                      style={{ maxHeight: "390px", marginTop: "0px" }}
                    >
                      <div className="checkbox-list">
                        <div className="screen-content">
                          <p
                            contentEditable={editTemplate}
                            onBlur={(e) => {
                              setForm2({
                                ...form2,
                                description: e.target.innerText,
                              });
                            }}
                          >
                            {form2.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="screen-footer">
                    <div
                      className="btn-text-style py-0"
                      contentEditable={editTemplate}
                      onBlur={(e) => {
                        setForm2({
                          ...form2,
                          returnHomeButtonText: e.target.innerText,
                        });
                      }}
                    >
                      {form2.returnHomeButtonText}
                    </div>
                    <div className="footer-nav-btn">
                      <div
                        className="btn-style"
                        contentEditable={editTemplate}
                        onBlur={(e) => {
                          setForm2({
                            ...form2,
                            buttonText: e.target.innerText,
                          });
                        }}
                      >
                        {form2.buttonText}
                      </div>
                    </div>
                  </div>
                </ScreenLayout>
                {insightSubForm?.map((item, index, arr) => {
                  return (
                    <div key={index} className="position-relative">
                      {editTemplate && index > 0 ? (
                        <i
                          className="far fa-trash-alt text-danger border rounded-2 border-warning  p-2 position-absolute  cursor-pointer"
                          style={{
                            top: "10px",
                            right: "20px",
                          }}
                          onClick={() => removeInsightForms(index)}
                          aria-hidden="true"
                        ></i>
                      ) : null}

                      <div
                        className={
                          index > 0 && editTemplate
                            ? "d-flex border border-success p-1"
                            : "d-flex "
                        }
                        style={{ gap: "20px", borderRadius: "20px" }}
                      >
                        <div className="screen-layout">
                          <div className="screen-indicator">
                            <span className="time-indicator">9:41</span>
                            {index < 1 || !editTemplate ? (
                              <span className="mobile-indicator">
                                <img
                                  src={ImagesUrl?.mobile_Indicator}
                                  alt="Indicator"
                                />
                              </span>
                            ) : null}
                          </div>
                          <div className="screen-header">
                            <span className="back-btn">
                              <img src={ImagesUrl.back_arrow} alt="Arrow" />
                            </span>
                          </div>
                          <div className="screen-body px-50">
                            <div
                              className="checkbox-wrapper"
                              style={{ maxHeight: "395px", marginTop: "0px" }}
                            >
                              <div className="checkbox-list">
                                <div className="hastags-content">
                                  <p
                                    contentEditable={editTemplate}
                                    onBlur={(e) =>
                                      handleInsightForm(e, index, "subTitle")
                                    }
                                  >
                                    {item?.subTitle}
                                  </p>
                                </div>
                                <div className="screen-content">
                                  <p
                                    className="text-start"
                                    contentEditable={editTemplate}
                                    onBlur={(e) =>
                                      handleInsightForm(e, index, "description")
                                    }
                                  >
                                    {item?.description}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="screen-footer">
                            <div className="footer-nav-btn">
                              {index !== arr.length - 1 ? (
                                <div
                                  className="btn-text-style"
                                  contentEditable={editTemplate}
                                  onBlur={(e) =>
                                    handleInsightForm(e, index, "buttonText")
                                  }
                                >
                                  {item?.buttonText}
                                </div>
                              ) : (
                                <div
                                  className="btn-style"
                                  contentEditable={editTemplate}
                                  onBlur={(e) =>
                                    handleInsightForm(e, index, "buttonText")
                                  }
                                >
                                  {item?.buttonText}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                {editTemplate ? (
                  <div className="screen-layout border border-success">
                    <div className="d-flex justify-content-center align-items-center flex-column h-100">
                      <h3
                        className="cursor-pointer"
                        onClick={editTemplate ? addNewInsightForm : null}
                      >
                        <i class="fa-solid fa-square-plus"></i> Insight
                      </h3>
                    </div>
                  </div>
                ) : null}
                <ScreenLayout notHeaderRequire={true}>
                  <div className="screen-body">
                    <div className="screen-content">
                      <div
                        className="checkbox-wrapper"
                        style={{ maxHeight: "427px", marginTop: "0px" }}
                      >
                        <div className="checkbox-list">
                          <p
                            contentEditable={editTemplate}
                            onBlur={(e) => {
                              setMiddleForm({
                                ...middleForm,
                                description: e.target.innerText,
                              });
                            }}
                          >
                            {middleForm?.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="screen-footer">
                    <div className="footer-nav-btn">
                      <div
                        className="btn-style"
                        contentEditable={editTemplate}
                        onBlur={(e) => {
                          setMiddleForm({
                            ...middleForm,
                            buttonText: e.target.innerText,
                          });
                        }}
                      >
                        {middleForm?.buttonText}
                      </div>
                    </div>
                  </div>
                </ScreenLayout>

                {questionSubform?.map((item, index) => {
                  return (
                    <div key={index} className="position-relative">
                      {index > 0 && editTemplate ? (
                        <i
                          className="far fa-trash-alt text-danger border rounded-2 border-warning  p-2 position-absolute  cursor-pointer"
                          style={{
                            top: "10px",
                            right: "20px",
                          }}
                          onClick={() => removeQuestionForms(index)}
                          aria-hidden="true"
                        ></i>
                      ) : null}

                      <div
                        className={
                          index > 0 && editTemplate
                            ? "d-flex border border-success p-1"
                            : "d-flex "
                        }
                        style={{ gap: "20px", borderRadius: "20px" }}
                      >
                        <div className="screen-layout">
                          <div className="screen-indicator">
                            <span className="time-indicator">9:41</span>
                            {index < 1 || !editTemplate ? (
                              <span className="mobile-indicator">
                                <img
                                  src={ImagesUrl?.mobile_Indicator}
                                  alt="Indicator"
                                />
                              </span>
                            ) : null}
                          </div>
                          <div className="screen-header">
                            <span className="back-btn">
                              <img src={ImagesUrl.back_arrow} alt="Arrow" />
                            </span>
                          </div>
                          <div className="screen-body px-35">
                            <div
                              className="checkbox-wrapper"
                              style={{ maxHeight: "395px", marginTop: "0px" }}
                            >
                              <div className="checkbox-list">
                                <div className="bold-font-style">
                                  <p
                                    contentEditable={editTemplate}
                                    onBlur={(e) =>
                                      updateQuestion(e, index, "question")
                                    }
                                  >
                                    {item?.question}
                                  </p>
                                </div>
                                <div className="checkbox-wrapper">
                                  {item?.options?.map((data, i) => {
                                    return (
                                      <QuestionOptions
                                        i={i}
                                        data={data}
                                        editTemplate={editTemplate}
                                        item={item}
                                        index={index}
                                        handleQuestionForm={handleQuestionForm}
                                        handleQuestionAns={handleQuestionAns}
                                      />
                                    );
                                  })}
                                  <div
                                    class="reflection-item w-100 correct-text-div"
                                    key={index}
                                  >
                                    <div class="reflection-title">
                                      <p className="correct-text">Correct</p>
                                    </div>
                                    <div class="reflection-quote">
                                      <p
                                        className="feedback-inner-text"
                                        contenteditable={
                                          editTemplate ? "true" : "false"
                                        }
                                        onBlur={(e) =>
                                          updateQuestion(
                                            e,
                                            index,
                                            "correctDescription"
                                          )
                                        }
                                      >
                                        {item?.correctDescription}
                                      </p>
                                    </div>
                                  </div>
                                  <div
                                    class="reflection-item w-100 incorrect-text-div"
                                    key={index}
                                  >
                                    <div class="reflection-title">
                                      <p className="incorrect-text">
                                        Incorrect
                                      </p>
                                    </div>
                                    <div class="reflection-quote">
                                      <p
                                        className="feedback-inner-text"
                                        contenteditable={editTemplate}
                                        onBlur={(e) =>
                                          updateQuestion(
                                            e,
                                            index,
                                            "incorrectDescription"
                                          )
                                        }
                                      >
                                        {item?.incorrectDescription}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="screen-footer">
                            <div className="footer-nav-btn">
                              <div
                                className="btn-style"
                                contentEditable={editTemplate}
                                onBlur={(e) =>
                                  updateQuestion(e, index, "buttonText")
                                }
                              >
                                {item?.buttonText}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                {editTemplate ? (
                  <div className="screen-layout border border-success">
                    <div className="d-flex justify-content-center align-items-center flex-column h-100">
                      <h3
                        className="cursor-pointer"
                        onClick={editTemplate ? addNewQuestionForm : null}
                      >
                        <i class="fa-solid fa-square-plus"></i> Question
                      </h3>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>
    </TempLayout>
  );
};

export default JourneyQuiz;
