const QUERY_KEYS = {
  ADMIN_LOGIN_QUERY_KEY: "ADMIN_LOGIN_QUERY_KEY",
  CHANGE_PASSWORD_QUERY_KEY: "CHANGE_PASSWORD_QUERY_KEY",

  GET_USER_LIST_QUERY_KEY: "GET_USER_LIST_QUERY_KEY",
  USER_DETAIL_QUERY_KEY: "USER_DETAIL_QUERY_KEY",
  USER_DELETE_QUERY_KEY: "USER_DELETE_QUERY_KEY",
  USER_EDIT_QUERY_KEY: "USER_EDIT_QUERY_KEY",
  USER_STATUS_CHANGE_QUERY_KEY: "USER_STATUS_CHANGE_QUERY_KEY",

  ADD_JOURNEY_QUERY_KEY: " ADD_JOURNEY_QUERY_KEY",
  EDIT_JOURNEY_QUERY_KEY: " EDIT_JOURNEY_QUERY_KEY",
  DETAIL_JOURNEY_QUERY_KEY: " DETAIL_JOURNEY_QUERY_KEY",
  STATUS_JOURNEY_QUERY_KEY: " STATUS_JOURNEY_QUERY_KEY",
  DELETE_JOURNEY_QUERY_KEY: " DELETE_JOURNEY_QUERY_KEY",
  LIST_JOURNEY_QUERY_KEY: "LIST_JOURNEY_QUERY_KEY",

  SKILLS_LIST_QUERY_KEY: "SKILLS_LIST_QUERY_KEY",
  SKILL_ADD_QUERY_KEY: "SKILL_ADD_QUERY_KEY",
  SKILL_EDIT_QUERY_KEY: "SKILL_EDIT_QUERY_KEY",
  SKILL_DETAIL_QUERY_KEY: "SKILL_DETAIL_QUERY_KEY",
  SKILL_STATUS_QUERY_KEY: "SKILL_STATUS_QUERY_KEY",
  SKILL_DELETE_QUERY_KEY: "SKILL_DELETE_QUERY_KEY",

  STEPS_LIST_QUERY_KEY: "STEPS_LIST_QUERY_KEY",
  STEPS_ADD_QUERY_KEY: "STEPS_ADD_QUERY_KEY",
  STEPS_EDIT_QUERY_KEY: "STEPS_EDIT_QUERY_KEY",
  STEPS_STATUS_QUERY_KEY: "STEPS_STATUS_QUERY_KEY",
  STEPS_DETAIL_QUERY_KEY: "STEPS_DETAIL_QUERY_KEY",
  STEPS_DELETE_QUERY_KEY: "STEPS_DELETE_QUERY_KEY",

  TEMPLATE_IMAGES_QUERY_KEY: "TEMPLATE_IMAGES_QUERY_KEY",
  TEMPLATE_DATA_ADD_QUERY_KEY: "TEMPLATE_DATA_ADD_QUERY_KEY",

  EDIT_CONTENT_QUERY_KEY: "EDIT_CONTENT_QUERY_KEY",
  CONTENT_QUERY_KEY: "CONTENT_QUERY_KEY",

  ADD_ABOUT_US_QUERY_KEY: "ADD_ABOUT_US_QUERY_KEY",
  EDIT_ABOUT_US_QUERY_KEY: "EDIT_ABOUT_US_QUERY_KEY",
  DELETE_ABOUT_US_QUERY_KEY: "DELETE_ABOUT_US_QUERY_KEY",
  ABOUT_US_QUERY_KEY: "ABOUT_US_QUERY_KEY",

  SUPPORT_TYPE_QUERY_KEY: "SUPPORT_TYPE_QUERY_KEY",
  SUPPORT_FAQ_QUERY_KEY: "SUPPORT_FAQ_QUERY_KEY",
  EDIT_SUPPORT_FAQ_QUERY_KEY: "EDIT_SUPPORT_FAQ_QUERY_KEY",
  DELETE_SUPPORT_FAQ_QUERY_KEY: "DELETE_SUPPORT_FAQ_QUERY_KEY",
  ADD_SUPPORT_FAQ_QUERY_KEY: "ADD_SUPPORT_FAQ_QUERY_KEY",

  EDIT_EXERCISES_QUERY_KEY: "EDIT_EXERCISES_QUERY_KEY",
  DELETE_EXERCISES_QUERY_KEY: "DELETE_EXERCISES_QUERY_KEY",
  ADD_EXERCISES_QUERY_KEY: "ADD_EXERCISES_QUERY_KEY",
  LIST_EXERCISES_QUERY_KEY: "LIST_EXERCISES_QUERY_KEY",
  VIEW_EXERCISE_QUERY_KEY: "VIEW_EXERCISE_QUERY_KEY",
  EXERCISE_STATUS_QUERY_KEY: "EXERCISE_STATUS_QUERY_KEY",
  ADD_TEMPLATE_FORM_IMAGES_QUERY_KEY: "ADD_TEMPLATE_FORM_IMAGES_QUERY_KEY",
  ADD_TEMPLATE_EXERCISE_DATA_QUERY_KEY: "ADD_TEMPLATE_EXERCISE_DATA_QUERY_KEY",

  LEADERBOARD_LIST_QUERY_KEY: "LEADERBOARD_LIST_QUERY_KEY",
  RESOURCE_LIST_QUERY_KEY: "RESOURCE_LIST_QUERY_KEY",
  SEND_NOTIFICATION_QUERY_KEY: "SEND_NOTIFICATION_QUERY_KEY",
  LEADERBOARD_MANAGEMENT_QUERY_KEY: "LEADERBOARD_MANAGEMENT_QUERY_KEY",
  SEND_SCHEDULE_NOTIFICATION_QUERY_KEY: "SEND_SCHEDULE_NOTIFICATION_QUERY_KEY",
  LIST_SCHEDULE_NOTIFICATION_QUERY_KEY: "LIST_SCHEDULE_NOTIFICATION_QUERY_KEY",
  DELETE_SCHEDULE_NOTIFICATION_QUERY_KEY:
    "DELETE_SCHEDULE_NOTIFICATION_QUERY_KEY",
  EDIT_SCHEDULE_NOTIFICATION_QUERY_KEY: "EDIT_SCHEDULE_NOTIFICATION_QUERY_KEY",
  REFLECTION_RATINGS_QUERY_KEY: "REFLECTION_RATINGS_QUERY_KEY",
  ACTION_RATINGS_QUERY_KEY: "ACTION_RATINGS_QUERY_KEY",
  SKILL_TYPE_QUERY_KEY: "SKILL_TYPE_QUERY_KEY",
  LIBRARY_IMAGE_QUERY_KEY: "LIBRARY_IMAGE_QUERY_KEY",
  ADD_LIBRARY_QUERY_KEY: "ADD_LIBRARY_QUERY_KEY",
  LIBRARY_LISTING_QUERY_KEY: "LIBRARY_LISTING_QUERY_KEY",
  LIBRARY_DELETE_QUERY_KEY: "LIBRARY_DELETE_QUERY_KEY",
};

export default QUERY_KEYS;
