import * as Yup from "yup";

const addSkillInitialValues = {
  skillName: "",
  skillDescription: "",
  skillImage: "",
  skillBackgroundImg: "",
  skillQuestion: "",
  skillQuestionImage: "",
};
const addSkillValidationSchema = Yup.object().shape({
  skillName: Yup.string().trim().required(" Name is required*"),
  skillDescription: Yup.string().trim().required("Description  is required*"),
  skillImage: Yup.string().trim().required("Image is required*"),
  skillBackgroundImg: Yup.string().trim(),
  skillQuestionImage: Yup.string().trim().required("Image is required*"),
  skillQuestion: Yup.string().trim().required("Question is required*"),
});
const editSkillInitialValues = {
  editSkillName: "",
  editSkillDescription: "",
  editSkillImage: "",
  editSkillBackgroundImg: "",
};
const editSkillValidationSchema = Yup.object().shape({
  editSkillName: Yup.string().trim().required(" Name is required*"),
  editSkillDescription: Yup.string()
    .trim()
    .required("Description  is required*"),
  editSkillImage: Yup.string().trim().required("Image is required*"),
  editSkillBackgroundImg: Yup.string().trim().required("Image is required*"),
});
export {
  addSkillInitialValues,
  addSkillValidationSchema,
  editSkillInitialValues,
  editSkillValidationSchema,
};
