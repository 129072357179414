import * as Yup from "yup";

const addAboutUsInitialValues = {
  aboutTitle: "",
  aboutDescription: "",
  aboutImage: "",
};
const addAboutUsValidationSchema = Yup.object().shape({
  aboutTitle: Yup.string().trim().required("Title is required*"),
  aboutDescription: Yup.string().trim().required("Description  is required*"),
  aboutImage: Yup.string().trim().required("Image is required*"),
});
export { addAboutUsInitialValues, addAboutUsValidationSchema };
