import React, { useEffect, useState } from "react";
import useGet from "../../hooks/useGet";
import ENDPOINTS from "../../services/endPoints";
import QUERY_KEYS from "../../services/queryKeys";
import CustomModal from "../../components/customModal/customModal";
import TextEditor from "../../components/textEdtior";
import usePut from "../../hooks/usePut";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";

export default function TermsAndCond() {
  const [editorModal, setEditorModal] = useState(false);
  const [isLoadingComponent, setLoadingComponent] = useState(true);
  const {
    data: termsAndConditionsData,
    isLoading: isLoadingTermsAndConditions,
  } = useGet(ENDPOINTS.CONTENT, QUERY_KEYS.CONTENT_QUERY_KEY, {
    type: 2,
  });

  useEffect(() => {
    setTimeout(() => {
      setLoadingComponent(false);
    }, 500);
  }, []);

  const {
    isLoading: isLoadingTermsAndConditionsEdit,
    mutateAsync: mutateAsyncEditTermsAndConditions,
  } = usePut(ENDPOINTS.EDIT_CONTENT, QUERY_KEYS.EDIT_CONTENT_QUERY_KEY);

  const [termsAndConditions, setTermsAndConditions] = useState(
    termsAndConditionsData?.data?.description
  );

  useEffect(() => {
    setTermsAndConditions(termsAndConditionsData?.data?.description);
  }, [termsAndConditionsData]);

  const handelEditorModal = () => setEditorModal(!editorModal);

  const handleChange = (e, i, k) => {
    setTermsAndConditions(e);
  };

  const onSaveTermsAndConditions = async () => {
    try {
      const response = await mutateAsyncEditTermsAndConditions({
        _id: termsAndConditionsData?.data?._id,
        description: termsAndConditions,
      });

      if (response.statusCode === 201) {
        toast.success("Terms and Conditions Updated!");
        handelEditorModal();
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12 mt-4 px-1">
          <div className="card">
            <div className="card-header pb-0 px-3">
              <div className="position-relative my-3 d-flex justify-center">
                <h5 className="mb-0">Terms And conditions</h5>
                {!isLoadingComponent && !isLoadingTermsAndConditions && (
                  <div className="ms-auto text-end ">
                    <a
                      title="Edit"
                      className="btn btn-link text-dark px-3 mb-0"
                      onClick={() => handelEditorModal()}
                    >
                      <i
                        className="fas fa-pencil-alt text-dark me-2"
                        aria-hidden="true"
                      />
                      Edit
                    </a>
                  </div>
                )}
              </div>
            </div>

            <div className="card-body pt-4 p-3">
              {isLoadingComponent ? (
                <Skeleton
                  style={{
                    width: "1",
                    height: "600px",
                    marginBottom: "10px",
                  }}
                />
              ) : isLoadingTermsAndConditions ? (
                <ul className="list-group w-100">
                  <Skeleton
                    style={{
                      width: "1",
                      height: "600px",
                      marginBottom: "10px",
                    }}
                  />
                </ul>
              ) : (
                <ul className="list-group">
                  <li className="list-group-item border-0 d-flex p-4 mb-2 bg-gray-100 border-radius-lg">
                    <div className="d-flex flex-row cursor-pointer w-100">
                      <div className="d-flex flex-column">
                        <div
                          className="text-dark font-weight-normal"
                          dangerouslySetInnerHTML={{
                            __html: termsAndConditions,
                          }}
                        />
                      </div>
                    </div>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        open={editorModal}
        title="Terms and Conditions"
        cancelTitle="save"
        actionButtonClass="bg-danger"
        isLoading={isLoadingTermsAndConditionsEdit}
        onClose={onSaveTermsAndConditions}
        customClass={"modal-xl"}
        actionButtonNotRequire={true}
      >
        <TextEditor
          stateKey={"termsAndConditions"}
          index={1}
          value={termsAndConditions}
          handleChange={handleChange}
        />
      </CustomModal>
    </div>
  );
}
