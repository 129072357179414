/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "../../routes/routePath";
import CustomModal from "../../components/customModal/customModal";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import usePost from "../../hooks/usePost";
import ENDPOINTS from "../../services/endPoints";
import QUERY_KEYS from "../../services/queryKeys";
import {
  addStepInitialValues,
  addStepValidationSchema,
} from "../../utils/validations/step";
import userGlobalConfig from "../../utils/constants/globalConfig";
import useGet from "../../hooks/useGet";
import StepListingSkeleton from "../../utils/skeleton/stepListingSkeleton";
import NotFound from "../../components/notFound";
import Toggle from "../../components/UI/toggle";
import ExerciseList from "../exerciseManagement/exerciseList";
import {
  addExerciseInitialValues,
  addExerciseValidationSchema,
} from "../../utils/validations/exercises";
import { numberOnly } from "../../utils/helpers";

const StepsList = () => {
  const [uploadImg, setUploadImg] = useState(null);
  const uploadImageRef = useRef(null);
  const [stepModal, setStepModal] = useState({
    title: "",
    data: {},
    open: false,
    type: "",
    actionTitle: "",
    onConfirm: null,
  });
  const [exerciseModal, setExerciseModal] = useState({
    title: "",
    data: {},
    open: false,
    type: "",
    actionTitle: "",
    onConfirm: null,
  });
  const skillId = JSON.parse(localStorage.getItem(userGlobalConfig?.SKILL_ID));
  const { mutateAsync: addStepMutateAsync, isLoading: isLoadingAddStep } =
    usePost(ENDPOINTS.STEPS_ADD, QUERY_KEYS.STEPS_ADD_QUERY_KEY);
  const {
    mutateAsync: addExerciseMutateAsync,
    isLoading: isLoadingAddExercise,
  } = usePost(ENDPOINTS.ADD_EXERCISE, QUERY_KEYS.ADD_EXERCISES_QUERY_KEY);
  const {
    data: stepListData,
    isLoading: isLoadingStep,
    error: errorStep,
    isSuccess: isSuccessSkill,
    refetch: refetchStepList,
  } = useGet(ENDPOINTS.STEPS_LIST, QUERY_KEYS.STEPS_LIST_QUERY_KEY, {
    skill_id: skillId,
  });

  const {
    data: exerciseListData,
    isLoading: isLoadingExercises,
    error: errorExercises,
    isSuccess: isSuccessExercises,
    refetch: refetchExercises,
  } = useGet(ENDPOINTS.EXERCISES_LIST, QUERY_KEYS.LIST_EXERCISES_QUERY_KEY, {
    skill_id: skillId,
  });

  const stepData = stepListData?.data?.step_list;
  const stepId = stepModal?.data?.data?._id;

  const [activeToggleButton, setActiveToggleButton] = useState(
    localStorage.getItem(userGlobalConfig.TAB_TYPE) ?? "Steps"
  );

  const handleToggleClick = (value) => {
    localStorage.setItem(userGlobalConfig.TAB_TYPE, value);
    setActiveToggleButton(value);
  };

  const nav = useNavigate();
  const viewStepDetail = (item, value) => {
    nav(RoutePath.STEPS_DETAIL.path, {
      state: { headerName: RoutePath.STEPS_DETAIL.title },
    });
    localStorage.setItem(userGlobalConfig.STEP_ID, JSON.stringify(item?._id));
    localStorage.setItem(
      userGlobalConfig.SUB_STEP_ID,
      JSON.stringify(value?._id)
    );
  };

  const {
    errors,
    handleBlur,
    handleChange,
    touched,
    resetForm,
    handleSubmit,
    values,
    setFieldValue,
  } = useFormik({
    initialValues: addStepInitialValues,
    validationSchema: addStepValidationSchema,
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("skill_id", skillId);
      formData.append("step_id", stepId ?? "");
      formData.append("name", values?.stepName);
      formData.append("points", values?.stepPoints);
      formData.append("description", values?.stepDescription);
      formData.append("image", values?.stepImage);
      try {
        const response = await addStepMutateAsync(formData);
        if (response.statusCode === 201) {
          toast.success(response?.message);
          resetForm();
          resetForm(null);
          refetchStepList();
          handleCloseModal();
        } else {
          toast.error(response?.message);
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    },
  });

  const {
    errors: errorsExercise,
    handleBlur: handleBlurExercise,
    handleChange: handleChangeExercise,
    touched: touchedExercise,
    resetForm: resetFormExercise,
    handleSubmit: handleSubmitExercise,
    values: valuesExercise,
    setFieldValue: setFieldValueExercise,
  } = useFormik({
    initialValues: addExerciseInitialValues,
    validationSchema: addExerciseValidationSchema,
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("skill_id", skillId);
      formData.append("title", valuesExercise?.exerciseTitle);
      formData.append("extimated_time", valuesExercise?.exerciseTime);
      formData.append("image", valuesExercise?.exerciseImage);
      formData.append(
        "exercise_json",
        JSON.stringify({
          name: "Intro",
        })
      );
      formData.append("type", "1");

      try {
        const response = await addExerciseMutateAsync(formData);
        if (response.statusCode === 201) {
          toast.success(response?.message);
          resetFormExercise();
          refetchExercises();
          handleCloseExerciseModal();
        } else {
          toast.error(response?.message);
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    },
  });

  const viewExercise = (id) => {
    nav(RoutePath.EXERCISE_DETAIL.path, {
      state: {
        headerName: RoutePath.EXERCISE_DETAIL.title,
      },
    });
    localStorage.setItem(userGlobalConfig.EXERCISE_ID, JSON.stringify(id));
  };

  const onChangeFile = (target) => {
    const selectedFile = target.files[0];
    setUploadImg(URL.createObjectURL(selectedFile));
    setFieldValue("stepImage", selectedFile);
    target.value = "";
  };
  const onChangeExerciseFile = (target) => {
    const selectedFile = target.files[0];
    setUploadImg(URL.createObjectURL(selectedFile));
    setFieldValueExercise("exerciseImage", selectedFile);
    target.value = "";
  };
  const handleSelectUploadImage = () => {
    if (uploadImageRef.current) {
      uploadImageRef.current.click();
    }
  };

  const handelRemoveUploadImg = () => {
    setUploadImg(null);
    setFieldValue("stepImage", "");
  };
  const handelRemoveUploadExerciseImg = () => {
    setUploadImg(null);
    setFieldValueExercise("exerciseImage", "");
  };
  const handleModalOpen = (type, data) => {
    if (type === "Add") {
      setStepModal({
        open: true,
        title: "Add Step",
        actionTitle: "Add",
        type: type,
        onConfirm: handleSubmit,
        data: {},
      });
    } else if (type === "subStep") {
      setStepModal({
        open: true,
        title: "Add Sub-step",
        actionTitle: "Add",
        type: type,
        onConfirm: handleSubmit,
        data: { data },
      });
    } else return null;
  };

  const handleAddExerciseModalOpen = (type, data) => {
    setExerciseModal({
      open: true,
      title: "Add Exercise",
      actionTitle: "Add",
      type: type,
      onConfirm: handleSubmitExercise,
      data: {},
    });
  };

  const handleCloseModal = () => {
    setStepModal({
      open: false,
      data: {},
    });
    resetForm();
    resetForm(null);
  };

  const handleCloseExerciseModal = () => {
    setExerciseModal({
      open: false,
      data: {},
    });
    resetFormExercise();
    resetForm(null);
  };
  const returnModal = () => {
    if (stepModal.type === "Add") {
      return (
        <CustomModal
          open={stepModal.open}
          title={stepModal.title}
          actionTitle={stepModal.actionTitle}
          cancelTitle={"Cancel"}
          actionButtonClass={"bg-danger"}
          onConfirm={stepModal.onConfirm}
          isLoading={isLoadingAddStep}
          onClose={handleCloseModal}
        >
          <div>
            <form>
              <div className="mb-3">
                <label> Name</label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="Enter Step Name"
                  name="stepName"
                  value={values.stepName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              {touched.stepName && errors.stepName && (
                <p className="error">{errors.stepName}</p>
              )}
              <div className="mb-3">
                <label> points</label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="Enter step Point "
                  name="stepPoints"
                  value={values.stepPoints}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyPress={numberOnly}
                />
              </div>
              {touched.stepPoints && errors.stepPoints && (
                <p className="error">{errors.stepPoints}</p>
              )}
              <div className="mb-3">
                <label> Description </label>
                <textarea
                  className="form-control form-control-lg"
                  placeholder="Enter step Description"
                  rows="3"
                  cols="50"
                  name="stepDescription"
                  value={values.stepDescription}
                  onChange={handleChange}
                  onBlur={handleBlur}
                ></textarea>
              </div>
              {touched.stepDescription && errors.stepDescription && (
                <p className="error">{errors.stepDescription}</p>
              )}

              <div className="drag-input-field mt-3">
                <label>Upload Image </label>
                <input
                  type="file"
                  accept="image/*"
                  name="stepImage"
                  onBlur={handleBlur}
                  onChange={({ target }) => {
                    onChangeFile(target);
                  }}
                  ref={uploadImageRef}
                />
                {values?.stepImage?.length === 0 ? (
                  <label className="m-0" onClick={handleSelectUploadImage}>
                    <span className="upload-text">
                      <i className="fa-solid fa-cloud-arrow-up" />
                      Upload image
                    </span>
                  </label>
                ) : (
                  <div className="position-relative border-1 border border-gray rounded-2 d-flex justify-content-center p-2">
                    <img
                      src={uploadImg}
                      style={{
                        height: "200px",
                        width: "200px",

                        objectFit: "contain",
                        borderRadius: "5px",
                        border: "solid 1px #8080808a",
                      }}
                      alt="..."
                    />
                    <button
                      className="removeimg"
                      type="button"
                      onClick={() => handelRemoveUploadImg()}
                    >
                      X
                    </button>
                  </div>
                )}
              </div>
              {touched.stepImage && errors.stepImage && (
                <p className="error" style={{ marginTop: "4px" }}>
                  {errors.stepImage}
                </p>
              )}
            </form>
          </div>
        </CustomModal>
      );
    } else if (stepModal.type === "subStep") {
      return (
        <CustomModal
          open={stepModal.open}
          title={stepModal.title}
          actionTitle={stepModal.actionTitle}
          cancelTitle={"Cancel"}
          actionButtonClass={"bg-danger"}
          onConfirm={stepModal.onConfirm}
          isLoading={isLoadingAddStep}
          onClose={handleCloseModal}
        >
          <div>
            <form>
              <div className="mb-3">
                <label> Name</label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="Enter Step Name"
                  name="stepName"
                  value={values.stepName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              {touched.stepName && errors.stepName && (
                <p className="error">{errors.stepName}</p>
              )}
              <div className="mb-3">
                <label> Point</label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="Enter step Point"
                  name="stepPoints"
                  value={values.stepPoints}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyPress={numberOnly}
                />
              </div>
              {touched.stepPoints && errors.stepPoints && (
                <p className="error">{errors.stepPoints}</p>
              )}
              <div className="mb-3">
                <label> Description </label>
                <textarea
                  className="form-control form-control-lg"
                  placeholder="Enter step Description"
                  rows="3"
                  cols="50"
                  name="stepDescription"
                  value={values.stepDescription}
                  onChange={handleChange}
                  onBlur={handleBlur}
                ></textarea>
              </div>
              {touched.stepDescription && errors.stepDescription && (
                <p className="error">{errors.stepDescription}</p>
              )}

              <div className="drag-input-field mt-3">
                <label>Upload Image </label>
                <input
                  type="file"
                  accept="image/*"
                  name="stepImage"
                  onBlur={handleBlur}
                  onChange={({ target }) => {
                    onChangeFile(target);
                  }}
                  ref={uploadImageRef}
                />
                {values?.stepImage?.length === 0 ? (
                  <label className="m-0" onClick={handleSelectUploadImage}>
                    <span className="upload-text">
                      <i className="fa-solid fa-cloud-arrow-up" />
                      Upload image
                    </span>
                  </label>
                ) : (
                  <div className="position-relative border-1 border border-gray rounded-2 d-flex justify-content-center p-2">
                    <img
                      src={uploadImg}
                      style={{
                        height: "200px",
                        width: "100%",
                        objectFit: "cover",
                        borderRadius: "5px",
                      }}
                      alt="..."
                    />
                    <button
                      className="removeimg"
                      type="button"
                      onClick={() => handelRemoveUploadImg()}
                    >
                      X
                    </button>
                  </div>
                )}
              </div>
              {touched.stepImage && errors.stepImage && (
                <p className="error" style={{ marginTop: "4px" }}>
                  {errors.stepImage}
                </p>
              )}
            </form>
          </div>
        </CustomModal>
      );
    } else return null;
  };

  const returnExerciseModal = () => {
    return (
      <CustomModal
        open={exerciseModal.open}
        title={exerciseModal.title}
        actionTitle={exerciseModal.actionTitle}
        cancelTitle={"Cancel"}
        actionButtonClass={"bg-danger"}
        onConfirm={exerciseModal.onConfirm}
        isLoading={isLoadingAddExercise}
        onClose={handleCloseExerciseModal}
      >
        <div>
          <form>
            <div className="mb-3">
              <label>Title</label>
              <input
                type="text"
                className="form-control form-control-lg"
                placeholder="Enter Exercise Title"
                name="exerciseTitle"
                value={valuesExercise.exerciseTitle}
                onChange={handleChangeExercise}
                onBlur={handleBlurExercise}
              />
            </div>
            {touchedExercise.exerciseTitle && errorsExercise.exerciseTitle && (
              <p className="error">{errorsExercise.exerciseTitle}</p>
            )}
            <div className="mb-3">
              <label>Time Estimated</label>
              <input
                type="text"
                className="form-control form-control-lg"
                placeholder="Enter Exercise Estimated Time"
                name="exerciseTime"
                value={valuesExercise.exerciseTime}
                onChange={handleChangeExercise}
                onBlur={handleBlurExercise}
              />
            </div>
            {touchedExercise.exerciseTime && errorsExercise.exerciseTime && (
              <p className="error">{errorsExercise.exerciseTime}</p>
            )}

            <div className="drag-input-field mt-3">
              <label>Upload Image </label>
              <input
                type="file"
                accept="image/*"
                name="exerciseImage"
                onBlur={handleBlurExercise}
                onChange={({ target }) => {
                  onChangeExerciseFile(target);
                }}
                ref={uploadImageRef}
              />
              {valuesExercise?.exerciseImage?.length === 0 ? (
                <label className="m-0" onClick={handleSelectUploadImage}>
                  <span className="upload-text">
                    <i className="fa-solid fa-cloud-arrow-up" />
                    Upload image
                  </span>
                </label>
              ) : (
                <div className="position-relative border-1 border border-gray rounded-2 d-flex justify-content-center p-2">
                  <img
                    src={uploadImg}
                    style={{
                      height: "200px",
                      width: "100%",
                      objectFit: "cover",
                      borderRadius: "5px",
                    }}
                    alt="..."
                  />
                  <button
                    className="removeimg"
                    type="button"
                    onClick={() => handelRemoveUploadExerciseImg()}
                  >
                    X
                  </button>
                </div>
              )}
            </div>
            {touchedExercise.exerciseImage && errorsExercise.exerciseImage && (
              <p className="error" style={{ marginTop: "4px" }}>
                {errorsExercise.exerciseImage}
              </p>
            )}
          </form>
        </div>
      </CustomModal>
    );
  };

  return (
    <div>
      <div className="card-wrapper">
        <div className="row">
          <div className="col-12">
            <div className="flex-end-btn-new">
              <Toggle
                leftValue="Steps"
                rightValue="Exercises"
                handleClick={handleToggleClick}
                active={activeToggleButton}
              />
              {isLoadingStep ? null : (
                <button
                  type="button"
                  className="btn btn-green mb-0 text-uppercase "
                  onClick={() =>
                    activeToggleButton === "Steps"
                      ? handleModalOpen("Add")
                      : handleAddExerciseModalOpen()
                  }
                >
                  Add {activeToggleButton}
                </button>
              )}
            </div>
          </div>
        </div>
        {activeToggleButton == "Steps" ? (
          <div className="row gy-4">
            {isLoadingStep ? (
              <StepListingSkeleton />
            ) : !stepData ? (
              <NotFound content="Steps List Not Found" />
            ) : (
              stepData?.map((item, index) => {
                return (
                  <div className="col-12">
                    <div className="steps-wrapper">
                      <div className="steps-heading">
                        <h2>Step {index + 1}</h2>
                      </div>
                      <div className="flex-wrap-outer">
                        <div className="flex-wrap-blk">
                          {item.substeps?.map((value, i) => {
                            return (
                              <div
                                className="steps-content-blk cursor-pointer"
                                onClick={() => viewStepDetail(item, value)}
                              >
                                <div className="steps-card-img ">
                                  <img src={value?.image} alt="Image" />
                                </div>
                                <div className="steps-detail">
                                  <h3>{value?.name}</h3>
                                  <span className="points">
                                    {value?.points}
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        <button
                          type="button"
                          className="addButton cursor-pointer  border border-success"
                          onClick={() => handleModalOpen("subStep", item)}
                        >
                          <div className="d-flex justify-content-center align-items-center flex-column h-100">
                            <h3 className="cursor-pointer">
                              <i class="fa-solid fa-square-plus"></i>
                            </h3>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        ) : (
          <ExerciseList
            onClick={viewExercise}
            skillId={skillId}
            loading={isLoadingExercises}
            list={exerciseListData}
          />
        )}
      </div>
      {returnModal()}
      {returnExerciseModal()}
    </div>
  );
};

export default StepsList;
