/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import CustomTable from "../../components/table/customTable";
import useGet from "../../hooks/useGet";
import { RoutePath } from "../../routes/routePath";
import userGlobalConfig from "../../utils/constants/globalConfig";
import QUERY_KEYS from "../../services/queryKeys";
import ENDPOINTS from "../../services/endPoints";
import { useNavigate } from "react-router-dom";
import { leaderBoardTableHeaderList } from "../../utils/seeds/tableHeaders";
import CustomModal from "../../components/customModal/customModal";
import { useFormik } from "formik";
import {
  addLeadershipInitialValues,
  addLeadershipValidationSchema,
} from "../../utils/validations/leaderboard";
import LeaderboardListRow from "../../components/table/tableRows/leaderboardListRow";
import ImagesUrl from "../../utils/constants/images";
import { toast } from "react-toastify";
import usePost from "../../hooks/usePost";

const LeaderBoardList = () => {
  const [countData, setCountData] = useState(15);
  const [pageNumber, setPageNumber] = useState(1);
  const [leaderboardModal, setLeaderboardModal] = useState(false);
  const [uploadImg, setUploadImg] = useState(null);
  const uploadImageRef = useRef(null);
  const nav = useNavigate();

  const {
    data: leaderboardListData,
    isLoading: isLoadingLeaderboard,
    refetch: refetchLeaderboard,
  } = useGet(
    ENDPOINTS.LEADERBOARD_LIST,
    QUERY_KEYS.LEADERBOARD_LIST_QUERY_KEY,
    {
      page: pageNumber,
      limit: countData,
    }
  );
  const {
    mutateAsync: addBackgroundImgMutateAsync,
    isLoading: isLoadingAddBackgroundImg,
  } = usePost(
    ENDPOINTS.LEADERBOARD_MANAGEMENT,
    QUERY_KEYS.LEADERBOARD_MANAGEMENT_QUERY_KEY
  );

  const handelView = (item) => {
    nav(RoutePath.USER_DETAIL.path, {
      state: { headerName: RoutePath?.USER_DETAIL?.title },
    });
    localStorage.setItem(userGlobalConfig.USER_ID, JSON.stringify(item?._id));
  };
  const handlePrevPage = () => {
    setPageNumber((prevPage) => prevPage - 1);
  };

  const handleNextPage = () => {
    setPageNumber((nextPage) => nextPage + 1);
  };
  const handleRowLimitChange = (newValue) => {
    if (
      pageNumber ===
      Math.ceil(leaderboardListData?.data?.totalRecords / countData)
    ) {
      setPageNumber(1);
    }
    setCountData(newValue);
  };
  const {
    handleBlur,
    handleSubmit,
    errors,
    touched,
    values,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: addLeadershipInitialValues,
    validationSchema: addLeadershipValidationSchema,
    onSubmit: async (value) => {
      const formData = new FormData();
      formData.append("background_image", value.backgroundImage);
      if (value.backgroundImage instanceof File) {
        try {
          const response = await addBackgroundImgMutateAsync(formData);
          if (response.statusCode === 200) {
            toast.success(response?.message);
            resetForm();
            setLeaderboardModal(false);
            refetchLeaderboard();
          } else {
            toast.error(response?.message);
          }
        } catch (error) {
          toast.error(error.response.data.message);
        }
      } else {
        setLeaderboardModal(false);
      }
    },
  });

  const onChangeFile = (target) => {
    const selectedFile = target.files[0];
    setUploadImg(URL.createObjectURL(selectedFile));
    setFieldValue("backgroundImage", selectedFile);
    target.value = "";
  };
  const handleSelectUploadImage = () => {
    if (uploadImageRef.current) {
      uploadImageRef.current.click();
    }
  };
  const handelRemoveUploadImg = () => {
    setUploadImg(null);
    setFieldValue("backgroundImage", "");
  };
  useEffect(() => {
    refetchLeaderboard();
  }, [pageNumber, countData]);
  useEffect(() => {
    setFieldValue(
      "backgroundImage",
      leaderboardListData?.data?.background_image
    );
  }, [leaderboardListData, leaderboardModal]);
  return (
    <div>
      <div className="background_div">
        <img
          src={
            leaderboardListData?.data?.background_image == null || undefined
              ? ImagesUrl.empty_image
              : leaderboardListData?.data?.background_image
          }
          height={200}
          width={200}
          style={{ borderRadius: "10px" }}
          alt="..."
        />

        <a
          title="Edit"
          className="btn btn-link text-dark px-3 mb-0 cursor-pointer"
          style={{ right: "0", top: "10px", position: "absolute" }}
          onClick={() => setLeaderboardModal(true)}
        >
          <i
            class="fas fs-5 fa-pencil-alt text-dark me-2"
            aria-hidden="true"
          ></i>
        </a>
      </div>
      <CustomModal
        customClass="md-modal"
        open={leaderboardModal}
        title={
          leaderboardListData?.data?.background_image == null || undefined
            ? "Add Leaderboard Image"
            : "Edit Leaderboard Image"
        }
        actionTitle="Save"
        cancelTitle="Cancel"
        actionButtonClass="bg-danger"
        onConfirm={handleSubmit}
        isLoading={isLoadingAddBackgroundImg}
        onClose={() => setLeaderboardModal(false)}
      >
        <div>
          <div className="drag-input-field mt-3">
            <label> Upload Image </label>
            <input
              type="file"
              accept="image/*"
              name="stepImage"
              onBlur={handleBlur}
              onChange={({ target }) => {
                onChangeFile(target);
              }}
              ref={uploadImageRef}
            />
            {values?.backgroundImage?.length === 0 ? (
              <label className="m-0" onClick={handleSelectUploadImage}>
                <span className="upload-text">
                  <i className="fa-solid fa-cloud-arrow-up" />
                  Upload image
                </span>
              </label>
            ) : (
              <div className="position-relative border-1 border border-gray rounded-2 d-flex justify-content-center p-2">
                <img
                  src={uploadImg === null ? values.backgroundImage : uploadImg}
                  style={{
                    height: "200px",
                    width: "200px",

                    objectFit: "contain",
                    borderRadius: "5px",
                    border: "solid 1px #8080808a",
                  }}
                  alt="..."
                />
                <button
                  className="removeimg"
                  type="button"
                  onClick={() => handelRemoveUploadImg()}
                >
                  X
                </button>
              </div>
            )}
          </div>
          {touched.backgroundImage && errors.backgroundImage && (
            <p className="error" style={{ marginTop: "4px" }}>
              {errors.backgroundImage}
            </p>
          )}
        </div>
      </CustomModal>
      <CustomTable
        isLoading={isLoadingLeaderboard}
        heading={"Leaderboard User List"}
        tableHeaderList={leaderBoardTableHeaderList}
        handlePrevPage={handlePrevPage}
        handleNextPage={handleNextPage}
        handleRowLimitChange={handleRowLimitChange}
        currentPage={pageNumber}
        totalPages={leaderboardListData?.data?.totalRecords}
        countData={countData}
      >
        <LeaderboardListRow
          pageNumber={pageNumber}
          countData={countData}
          onView={handelView}
          data={leaderboardListData?.data?.leaderboardList}
          isLoading={isLoadingLeaderboard}
        />
      </CustomTable>
    </div>
  );
};

export default LeaderBoardList;
